:root {
  --grey-01: rgba(238, 238, 238, 1);
  --h2-REG-font-family: "SourceSans Pro-Regular", Helvetica;
  --h2-REG-font-size: 42px;
  --h2-REG-font-style: normal;
  --h2-REG-font-weight: 400;
  --h2-REG-letter-spacing: -1.05px;
  --h2-REG-line-height: 120.00000476837158%;
  --h3-REG-font-family: "SourceSans Pro-Regular", Helvetica;
  --h3-REG-font-size: 35px;
  --h3-REG-font-style: normal;
  --h3-REG-font-weight: 400;
  --h3-REG-letter-spacing: -0.525px;
  --h3-REG-line-height: 139.9999976158142%;
  --h4-REG-font-family: "SourceSans Pro-Regular", Helvetica;
  --h4-REG-font-size: 29px;
  --h4-REG-font-style: normal;
  --h4-REG-font-weight: 400;
  --h4-REG-letter-spacing: -0.29px;
  --h4-REG-line-height: 139.9999976158142%;
  --h5-REG-font-family: "SourceSans Pro-Regular", Helvetica;
  --h5-REG-font-size: 24px;
  --h5-REG-font-style: normal;
  --h5-REG-font-weight: 400;
  --h5-REG-letter-spacing: -0.24px;
  --h5-REG-line-height: 139.9999976158142%;
  --h6-REG-font-family: "SourceSans Pro-Regular", Helvetica;
  --h6-REG-font-size: 20px;
  --h6-REG-font-style: normal;
  --h6-REG-font-weight: 400;
  --h6-REG-letter-spacing: -0.2px;
  --h6-REG-line-height: 139.9999976158142%;
  --labels-captions-REG-font-family: "SourceSans Pro-Regular", Helvetica;
  --labels-captions-REG-font-size: 12px;
  --labels-captions-REG-font-style: normal;
  --labels-captions-REG-font-weight: 400;
  --labels-captions-REG-letter-spacing: -0.12px;
  --labels-captions-REG-line-height: 139.9999976158142%;
  --neutral-0: rgba(255, 255, 255, 1);
  --neutral-10: rgba(246, 247, 248, 1);
  --neutral-30: rgba(233, 233, 233, 1);
  --neutral-40: rgba(210, 210, 210, 1);
  --neutral-50: rgba(188, 188, 188, 1);
  --neutral-60: rgba(121, 121, 121, 1);
  --neutral-70: rgba(77, 77, 77, 1);
  --neutral-80: rgba(54, 54, 54, 1);
  --neutral-90: rgba(32, 32, 32, 1);
  --paragraph-ALCPS-font-family: "SourceSans Pro-Bold", Helvetica;
  --paragraph-ALCPS-font-size: 14px;
  --paragraph-ALCPS-font-style: normal;
  --paragraph-ALCPS-font-weight: 700;
  --paragraph-ALCPS-letter-spacing: -0.21px;
  --paragraph-ALCPS-line-height: 139.9999976158142%;
  --paragraph-MED-font-family: "SourceSans Pro-SemiBold", Helvetica;
  --paragraph-MED-font-size: 14px;
  --paragraph-MED-font-style: normal;
  --paragraph-MED-font-weight: 600;
  --paragraph-MED-letter-spacing: -0.14px;
  --paragraph-MED-line-height: 139.9999976158142%;
  --paragraph-REG-font-family: "SourceSans Pro-Regular", Helvetica;
  --paragraph-REG-font-size: 14px;
  --paragraph-REG-font-style: normal;
  --paragraph-REG-font-weight: 400;
  --paragraph-REG-letter-spacing: -0.14px;
  --paragraph-REG-line-height: 139.9999976158142%;
  --primary-02: rgba(77, 159, 152, 1);
  --secondary-02: rgba(0, 223, 194, 1);
  --secondary-03: rgba(46, 80, 83, 1);
  --secondary-04: rgba(44, 62, 71, 1);
  --shadeswhite: rgba(255, 255, 255, 1);
  --subtitle-BLD-font-family: "SourceSans Pro-Bold", Helvetica;
  --subtitle-BLD-font-size: 17px;
  --subtitle-BLD-font-style: normal;
  --subtitle-BLD-font-weight: 700;
  --subtitle-BLD-letter-spacing: -0.17px;
  --subtitle-BLD-line-height: 139.9999976158142%;
  --subtitle-MED-font-family: "SourceSans Pro-SemiBold", Helvetica;
  --subtitle-MED-font-size: 17px;
  --subtitle-MED-font-style: normal;
  --subtitle-MED-font-weight: 600;
  --subtitle-MED-letter-spacing: -0.17px;
  --subtitle-MED-line-height: 139.9999976158142%;
  --subtitle-REG-font-family: "SourceSans Pro-Regular", Helvetica;
  --subtitle-REG-font-size: 17px;
  --subtitle-REG-font-style: normal;
  --subtitle-REG-font-weight: 400;
  --subtitle-REG-letter-spacing: -0.17px;
  --subtitle-REG-line-height: 139.9999976158142%;
  --white: rgba(255, 255, 255, 1);
}
