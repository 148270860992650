:root {
  --grey-01: #eee;
  --h2-REG-font-family: "SourceSans Pro-Regular", Helvetica;
  --h2-REG-font-size: 42px;
  --h2-REG-font-style: normal;
  --h2-REG-font-weight: 400;
  --h2-REG-letter-spacing: -1.05px;
  --h2-REG-line-height: 120%;
  --h3-REG-font-family: "SourceSans Pro-Regular", Helvetica;
  --h3-REG-font-size: 35px;
  --h3-REG-font-style: normal;
  --h3-REG-font-weight: 400;
  --h3-REG-letter-spacing: -.525px;
  --h3-REG-line-height: 140.0%;
  --h4-REG-font-family: "SourceSans Pro-Regular", Helvetica;
  --h4-REG-font-size: 29px;
  --h4-REG-font-style: normal;
  --h4-REG-font-weight: 400;
  --h4-REG-letter-spacing: -.29px;
  --h4-REG-line-height: 140.0%;
  --h5-REG-font-family: "SourceSans Pro-Regular", Helvetica;
  --h5-REG-font-size: 24px;
  --h5-REG-font-style: normal;
  --h5-REG-font-weight: 400;
  --h5-REG-letter-spacing: -.24px;
  --h5-REG-line-height: 140.0%;
  --h6-REG-font-family: "SourceSans Pro-Regular", Helvetica;
  --h6-REG-font-size: 20px;
  --h6-REG-font-style: normal;
  --h6-REG-font-weight: 400;
  --h6-REG-letter-spacing: -.2px;
  --h6-REG-line-height: 140.0%;
  --labels-captions-REG-font-family: "SourceSans Pro-Regular", Helvetica;
  --labels-captions-REG-font-size: 12px;
  --labels-captions-REG-font-style: normal;
  --labels-captions-REG-font-weight: 400;
  --labels-captions-REG-letter-spacing: -.12px;
  --labels-captions-REG-line-height: 140.0%;
  --neutral-0: #fff;
  --neutral-10: #f6f7f8;
  --neutral-30: #e9e9e9;
  --neutral-40: #d2d2d2;
  --neutral-50: #bcbcbc;
  --neutral-60: #797979;
  --neutral-70: #4d4d4d;
  --neutral-80: #363636;
  --neutral-90: #202020;
  --paragraph-ALCPS-font-family: "SourceSans Pro-Bold", Helvetica;
  --paragraph-ALCPS-font-size: 14px;
  --paragraph-ALCPS-font-style: normal;
  --paragraph-ALCPS-font-weight: 700;
  --paragraph-ALCPS-letter-spacing: -.21px;
  --paragraph-ALCPS-line-height: 140.0%;
  --paragraph-MED-font-family: "SourceSans Pro-SemiBold", Helvetica;
  --paragraph-MED-font-size: 14px;
  --paragraph-MED-font-style: normal;
  --paragraph-MED-font-weight: 600;
  --paragraph-MED-letter-spacing: -.14px;
  --paragraph-MED-line-height: 140.0%;
  --paragraph-REG-font-family: "SourceSans Pro-Regular", Helvetica;
  --paragraph-REG-font-size: 14px;
  --paragraph-REG-font-style: normal;
  --paragraph-REG-font-weight: 400;
  --paragraph-REG-letter-spacing: -.14px;
  --paragraph-REG-line-height: 140.0%;
  --primary-02: #4d9f98;
  --secondary-02: #00dfc2;
  --secondary-03: #2e5053;
  --secondary-04: #2c3e47;
  --shadeswhite: #fff;
  --subtitle-BLD-font-family: "SourceSans Pro-Bold", Helvetica;
  --subtitle-BLD-font-size: 17px;
  --subtitle-BLD-font-style: normal;
  --subtitle-BLD-font-weight: 700;
  --subtitle-BLD-letter-spacing: -.17px;
  --subtitle-BLD-line-height: 140.0%;
  --subtitle-MED-font-family: "SourceSans Pro-SemiBold", Helvetica;
  --subtitle-MED-font-size: 17px;
  --subtitle-MED-font-style: normal;
  --subtitle-MED-font-weight: 600;
  --subtitle-MED-letter-spacing: -.17px;
  --subtitle-MED-line-height: 140.0%;
  --subtitle-REG-font-family: "SourceSans Pro-Regular", Helvetica;
  --subtitle-REG-font-size: 17px;
  --subtitle-REG-font-style: normal;
  --subtitle-REG-font-weight: 400;
  --subtitle-REG-letter-spacing: -.17px;
  --subtitle-REG-line-height: 140.0%;
  --white: #fff;
}
/*# sourceMappingURL=index.5069954a.css.map */
